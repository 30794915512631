import AppVars from '@/enums/appVars';

const API = AppVars.ExternalApi;
const JOBS_API = AppVars.JobsApi;
const CONTENT_API = AppVars.ContentApi;

export default {
  AutocompleteCountries: `${API}/autocomplete/countries`,
  Categories: `${API}/job-categories`,
  ChallengeToken: `${API}/challenge-token`,
  Companies: `${API}/companies`,
  CompanyValues: `${API}/company-values`,
  CompanyTypes: `${API}/company-types`,
  Dashboard: `${API}/dashboard`,
  Expertise: `${API}/expertise`,
  RealtimeFeatureFlags: `${API}/featureflag`,
  HomepageCompanies: `${API}/homepage/companies`,
  HomepageCompaniesPerValues: `${API}/homepage/companies-values`,
  HomepageCompaniesRemote: `${API}/homepage/companies-remote`,
  HomepageJobs: `${API}/homepage/jobs`,
  HomepageTechNews: `${API}/homepage/tech-news`,
  HomepageAvailableTopics: `${API}/homepage/available-topics`,
  HomepageBlogs: `${API}/homepage/blogs`,
  HomepageTechHubStats: `${API}/tech-hub-stats`,
  InternalEmailContent: `${API}/qa/internal-email/content`,
  UnsubscribeEmailContent: `${API}/email/content/unsubscribe`,
  PauseEmailContent: `${API}/email/content/pause`,
  CancelPauseEmailContent: `${API}/email/content/cancel-pause`,
  GroupEmailContent: `${API}/qa/internal-email-group/content`,
  AllCombinationsEmailContent: `${API}/qa/content/all-combinations`,
  QAEmailUtcTime: `${API}/qa/content/utc-time`,
  NewsletterSignupToken: `${API}/email-only-signup-csrf-token`,
  NewsletterSignup: `${API}/auth/email-only-signup`,
  Signup: `${API}/auth/signup`,
  Jobs: `${API}/jobs`,
  JobsApi: `${API}/jobsapi`,
  LocationApi: `${API}/locationapi`,
  LocationApiAutocomplete: `${API}/locationapi/suggest/autocomplete`,
  LocationByCoordinates: `${API}/locationapi/reverse-geocode`,
  LocationByPlaceId: `${API}/locationapi/place`,
  JobsApiLegacyJobs: `${JOBS_API}/legacy-jobs`, // retrive only jobs
  JobsApiCollapsedJobs: `${JOBS_API}/legacy-collapsed-jobs`, // retrive companies with high volume jobs
  JobSubcategories: `${API}/job-subcategories`,
  JobsAlias: `${CONTENT_API}/aliases`,
  JobApply: `${API}/apply`,
  Onboarding: `${API}/onboarding`,
  Profile: `${API}/profile`,
  Regions: `${API}/regions`,
  RecentArticles: `${API}/v1/articles-recommendations`,
  TopStoriesArticles: `${API}/promoted-blogs`,
  Skills: `${API}/skills`,
  Topics: `${API}/topics`,
  User: `${API}/user`,
  Views: `${API}/views`,
  CourseGqlApi: `${API}/learninglabs/graphql`,
};
