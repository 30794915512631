






















































































































import {
  defineComponent,
  ref,
  computed,
  getCurrentInstance,
  ComputedRef,
  onMounted,
} from '@nuxtjs/composition-api';
import navigationHelper, { showNavMenu, getLinkAttributes } from '@/utils/navigation';
import { faStream } from '@fortawesome/pro-solid-svg-icons/faStream';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import regions from '@/api/regions';

import { Link } from '@/interfaces/links';
import { isSafari } from '@/utils/onboarding';
import { useVuetify } from '@/hooks/useVuetify';
import {
  blogsLink,
  companyLink,
  forEmployersLink,
  jobsLink,
  myItemsLink,
  nationalLink,
  remoteLink,
  salariesLink,
  techHubLink,
  techTopicLink,
  bestPlacesToWorkLink,
} from '@/enums/links';
import { useAuthentication } from '@/hooks/useAuthentication';
import { isFeatureFlagEnabled } from '@/utils/feature-flag';

const getMarketMenuLinks = () => {
  const TopLevelMenuLinks: ComputedRef<Link[]> = computed(() => {
    return [jobsLink, companyLink, blogsLink, remoteLink];
  });

  const ExpandedNationalMenuLinks: Set<Link> = new Set([
    { ...jobsLink, title: 'Tech Jobs', trackEventName: 'more_topnav_jobs_link' },
    { ...companyLink, trackEventName: 'more_topnav_companies_link' },
    { ...remoteLink, trackEventName: 'more_topnav_remote_link' },
    { ...blogsLink, trackEventName: 'more_tech_topics_link' },
    {
      ...salariesLink,
      trackEventName: 'more_topnav_salaries_link',
    },
    {
      ...nationalLink,
      trackEventName: 'more_topnav_national_link',
    },
  ]);
  return { TopLevelMenuLinks, ExpandedNationalMenuLinks };
};
const getNationalMenuLinks = () => {
  const menuState = ref({
    showBestPlacesToWork: false,
    initialized: false,
  });

  onMounted(async () => {
    try {
      menuState.value.showBestPlacesToWork = await isFeatureFlagEnabled(
        'best_places_to_work_navigation_link'
      );
    } catch (error) {
      console.error('Failed to check feature flag:', error);
    } finally {
      menuState.value.initialized = true;
    }
  });

  const TopLevelMenuLinks: ComputedRef<Link[]> = computed(() => {
    return [jobsLink, companyLink, techTopicLink, myItemsLink];
  });

  const ExpandedNationalMenuLinks = computed(() => {
    const links = new Set([
      { ...jobsLink, title: 'Tech Jobs', trackEventName: 'more_topnav_jobs_link' },
      { ...companyLink, trackEventName: 'more_topnav_companies_link' },
      { ...techTopicLink, trackEventName: 'more_tech_topics_link' },
      { ...remoteLink, trackEventName: 'more_topnav_remote_link' },
      { ...salariesLink, trackEventName: 'more_topnav_salaries_link' },
    ]);
    if (menuState.value.initialized && menuState.value.showBestPlacesToWork) {
      links.add({
        ...bestPlacesToWorkLink,
        trackEventName: 'more_best_work_places_link',
      });
    }

    links.add({
      ...techHubLink,
      trackEventName: 'topnav_tech_hubs_link',
    });

    return links;
  });

  return { TopLevelMenuLinks, ExpandedNationalMenuLinks };
};

export default defineComponent({
  name: 'MobileSubNavigation',
  setup() {
    const { signin, isAnonymous } = useAuthentication();

    const { TopLevelMenuLinks, ExpandedNationalMenuLinks } = regions.isNational()
      ? getNationalMenuLinks()
      : getMarketMenuLinks();

    const hideMenu = ref(false);
    const menuName = 'mobile-bottom-menu';
    const { breakpoints } = useVuetify();

    const isMobile = computed(() => breakpoints.value.mdAndDown);

    const { openDropdownNoDelay, closeDropdown, display, dropdownContent } = navigationHelper();
    const openedMenu = computed(() => display.value && dropdownContent.value === menuName);
    const openedAuthMenu = computed(
      () => display.value && dropdownContent.value === 'auth-user-mobile-menu'
    );
    const icons = { more: faStream, close: faTimes };

    const dropdownMenuIcon = computed(() => {
      return openedMenu.value ? icons.close : icons.more;
    });

    const bodyTag = document.querySelector('body');
    const regionId = regions.getCurrentRegionID();

    const handleMenu = () => {
      if (openedMenu.value) {
        closeDropdown();
      } else {
        openDropdownNoDelay(menuName);
      }
      bodyTag?.classList.toggle('mobile-menu-open');
    };

    const closeMenu = (menuItem: Link) => {
      if (openedMenu.value && menuItem.isSpaLink) {
        closeDropdown();
      }
    };

    const animationLeave = ref(false);
    const beforeAnimationLeave = () => {
      animationLeave.value = true;
    };
    const afterAnimationLeave = () => {
      animationLeave.value = false;
    };
    const instance = getCurrentInstance();
    /**
     * @returns Whether to show this navigation based
     * on the route.
     */
    const showNavigation = computed(() => {
      return showNavMenu(instance?.proxy.$route.name);
    });

    const handleTopMenuClick = (event: Event, menuItem: Link) => {
      if (isAnonymous.value && menuItem.url === myItemsLink.url) {
        event.preventDefault();
        signin(myItemsLink.url);
        return false;
      }

      closeMenu(menuItem);
    };

    return {
      handleTopMenuClick,
      TopLevelMenuLinks,
      ExpandedNationalMenuLinks,
      forEmployersLink,
      dropdownMenuIcon,
      hideMenu,
      openedMenu,
      handleMenu,
      regionId,
      getLinkAttributes,
      isSafari: isSafari(),
      isMobile,
      beforeAnimationLeave,
      afterAnimationLeave,
      animationLeave,
      closeMenu,
      showNavigation,
      openedAuthMenu,
    };
  },
});
